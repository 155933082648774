import { Button, FormControl, Grid, TextField, Typography } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import { FormError } from '../../../../store/types';
import { Step2Errors } from '../../../../store/Confirm/type';
import ConfirmService from './../../../../services/confirm.service';
import { errorHandler } from '../../../../helpers/errorHandler';
import { toast } from 'react-toastify';

type Step2Props = {
	setStep2Completed: React.Dispatch<React.SetStateAction<boolean>>;
	code: string;
};

const Step2Summary: FunctionComponent<Step2Props> = ({ setStep2Completed, code }) => {
	const [verificationCode, setVerificationCode] = useState<string>('');
	const [confirmCodeBtnDisabled, setConfirmCodeBtnDisabled] = useState<boolean>(false);
	const [resendBtnDisabled, setResendVerificationBtnDisabled] = useState<boolean>(false);

	const defaultError: FormError = {
		error: false,
		message: '',
	};

	const defaultStep2FormErrors: Step2Errors = {
		verificationCode: defaultError,
	};

	const [step2FormErrors, setStep2FormErrors] = useState<Step2Errors>(defaultStep2FormErrors);

	const handleVerificationCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setVerificationCode(event.target.value);
	};

	const onVerifyCode = async () => {
		setStep2FormErrors(defaultStep2FormErrors);
		const step2Errors = defaultStep2FormErrors;
		if (verificationCode.trim() === '') {
			const validatedInput = {
				verificationCode: {
					error: true,
					message: 'To pole jest wymagane',
				},
			};

			const error = Object.assign(step2Errors, validatedInput);
			setStep2FormErrors(error);
		} else {
			try {
				setConfirmCodeBtnDisabled(true);
				const verifyParams = {
					applicationUuid: code,
					confirmationCode: verificationCode,
				};
				const resendConfirmCodeResponse = await ConfirmService.confirmCodeForApp(verifyParams);
				if (resendConfirmCodeResponse.data.success) {
					setStep2Completed(true);
				}
				if (!resendConfirmCodeResponse.data.success) {
					toast.error('Nieprawidłowy kod');
				}
			} catch (error) {
				errorHandler(error);
			} finally {
				setConfirmCodeBtnDisabled(false);
			}
		}
	};

	const onResendVerifyCode = async () => {
		try {
			setResendVerificationBtnDisabled(true);
			const resendVerifyCodeResponse = await ConfirmService.resendConfirmationCodeForApp(code);
			if (resendVerifyCodeResponse.data.success) {
				toast.success('Kod wysłany na adres e-mail');
			}
		} catch (error) {
			errorHandler(error);
		} finally {
			setResendVerificationBtnDisabled(false);
		}
	};

	return (
		<Grid container className="step step-2" paddingX={4}>
			<Grid item md={12}>
				<Typography variant="h5" mb={3} className="header header-secondary">
					Weryfikacja
				</Typography>

				<Typography className="primary-txt-style" mb={2}>
					Sprawdź swoją skrzynkę pocztową, gdzie znajdziesz wiadomość z jednorazowym kodem weryfikacyjnym.
				</Typography>
				<Typography className="primary-txt-style" mb={3}>
					{' '}
					Skopiuj go lub przepisz w pole poniżej, aby zobaczyć i potwierdzić Twoje dane zgłoszone w formularzu rekrutacji.
				</Typography>
				<Grid container display={'flex'} flexDirection={'column'} alignItems={'center'}>
					<Grid item className="form-container">
						<FormControl className="inputs_containers" fullWidth>
							<TextField
								variant="outlined"
								fullWidth
								placeholder="Wpisz kod weryfikacyjny"
								size="small"
								className={step2FormErrors.verificationCode.error ? 'form-element form-element__input' : 'form-element form-element--margin-bottom form-element__input'}
								value={verificationCode}
								onChange={handleVerificationCodeChange}
								error={step2FormErrors.verificationCode.error}
								helperText={step2FormErrors.verificationCode.error && step2FormErrors.verificationCode.message}
							/>
						</FormControl>
					</Grid>
				</Grid>
				<Grid container marginBottom={3}>
					<Grid item width={'100%'} display={'flex'} justifyContent={'center'}>
						<Button className="button button--primary button--primary--normal" onClick={onVerifyCode} disabled={confirmCodeBtnDisabled} style={{ opacity: confirmCodeBtnDisabled ? '0.3' : '1' }}>
							Weryfikuj
						</Button>
					</Grid>
				</Grid>
				<Typography className="primary-txt-style">Nie otrzymałeś kodu?</Typography>
				<Typography className="primary-txt-style" mb={2}>
					Sprawdź zakładkę SPAM lub wyślij go ponownie
				</Typography>
				<Grid container marginY={3}>
					<Grid item width={'100%'} display={'flex'} justifyContent={'center'}>
						<Button className="button button--secondary button--secondary--wide" onClick={onResendVerifyCode} disabled={resendBtnDisabled} style={{ opacity: resendBtnDisabled ? '0.3' : '1' }}>
							Wyślij ponownie kod
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Step2Summary;
