import { Button, FormControl, Grid, TextField, Typography } from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import { convertBase64, validateNumbersAndSpacesOnly } from '../../../../helpers/utils';
import FileIcon from './../../../../assets/icons/file.svg';
import { RegisterData, Step2Errors } from '../../../../store/Register/type';
import { FormError } from '../../../../store/types';

import { validateEmail } from '../../../../helpers/utils';
import ValidationErrorIcon from './../../../../assets/icons/validation-error.svg';
import ValidationSuccessIcon from './../../../../assets/icons/validation-success.svg';
import { Parameters } from '../../../../store/Parameters/type';

type Step2Props = {
	parameters: Parameters;
	setStep2Completed: React.Dispatch<React.SetStateAction<boolean>>;
	formData: RegisterData;
	setFormData: React.Dispatch<React.SetStateAction<RegisterData>>;
	setIsStep1Active: React.Dispatch<React.SetStateAction<boolean>>;
	setIsStep2Active: React.Dispatch<React.SetStateAction<boolean>>;
	setIsStep3Active: React.Dispatch<React.SetStateAction<boolean>>;
};

const Step2: FunctionComponent<Step2Props> = ({ parameters, setStep2Completed, formData, setFormData, setIsStep1Active, setIsStep2Active, setIsStep3Active }) => {
	const [firstname, setFirstname] = useState<string>('');
	const [firstnameSuccess, setFirstnameSuccess] = useState<boolean>(false);

	const [lastname, setLastname] = useState<string>('');
	const [lastnameSuccess, setLastnameSuccess] = useState<boolean>(false);

	const [email, setEmail] = useState<string>('');
	const [emailSuccess, setEmailSuccess] = useState<boolean>(false);
	const [mobile, setMobile] = useState<string>('');
	const [mobileSuccess, setMobileSuccess] = useState<boolean>(false);
	const [workplaceCode, setWorkplaceCode] = useState<string>('');
	const [workplaceCodeSuccess, setWorkplaceCodeSuccess] = useState<boolean>(false);

	const [fileName, setFileName] = useState<string>('');
	// const [fileCode, setFileCode] = useState<string | File | Blob | ProgressEvent<FileReader>>('');
	const [fileCode, setFileCode] = useState<string>('');
	const [fileSize, setFileSize] = useState<number | null>(null);
	const [fileType, setFileType] = useState<string>('');
	const [fileCodeSuccess, setFileCodeSuccess] = useState<boolean>(false);

	const [fileDescription, setFileDescription] = useState<string | null>('');

	const defaultError: FormError = {
		error: false,
		message: '',
	};
	const defaultStep2FormErrors: Step2Errors = {
		firstname: defaultError,
		lastname: defaultError,
		email: defaultError,
		mobile: defaultError,
		workplaceCode: defaultError,
		fileCode: defaultError,
		fileDescription: defaultError,
	};
	const [step2FormErrors, setStep2FormErrors] = useState<Step2Errors>(defaultStep2FormErrors);

	const handleFirstnameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFirstname(event.target.value);
	};
	const handleLastnameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLastname(event.target.value);
	};
	const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value);
	};
	const handleMobileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setMobile(event.target.value);
	};
	const handleWorkplaceCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setWorkplaceCode(event.target.value);
	};

	const handleselectedFile = async (event: any) => {
		const file = event.target.files[0];
		if (file) {
			setFileName(file.name ? file.name : '');
			setFileSize(file.size);
			setFileType(file.type);
			const base64 = await convertBase64(file);
			setFileCode((base64 as string).split('base64,')[1]);
		}
	};

	const setInputsSuccessOnTrue = () => {
		setFirstnameSuccess(true);
		setLastnameSuccess(true);
		setEmailSuccess(true);
		setFileCodeSuccess(true);
		setMobileSuccess(true);
		setWorkplaceCodeSuccess(true);
	};

	const handleFileDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFileDescription(event.target.value);
	};

	useEffect(() => {
		if (formData) {
			if (
				formData.candidateFirstname &&
				formData.candidateLastname &&
				formData.candidateEmail &&
				formData.candidateMobile &&
				formData.workplaceCode &&
				formData.fileName &&
				formData.fileCode &&
				formData.fileDescription
			) {
				setInputsSuccessOnTrue();
			}
			setFirstname(formData.candidateFirstname || '');
			setLastname(formData.candidateLastname || '');
			setEmail(formData.candidateEmail || '');
			setMobile(formData.candidateMobile || '');
			setWorkplaceCode(formData.workplaceCode || '');
			setFileName(formData.fileName || '');
			setFileCode(formData.fileCode || '');
			setFileType(formData.fileType || '');
			setFileDescription(formData.fileDescription || '');
		}
	}, [formData]);

	const onSaveFormData = () => {
		setStep2FormErrors(defaultStep2FormErrors);
		const step2Errors = defaultStep2FormErrors;
		setInputsSuccessOnTrue();
		let errorCounter = 0;
		if (firstname.trim() === '') {
			const validatedInput = {
				firstname: {
					error: true,
					message: 'To pole jest wymagane',
				},
			};

			const error = Object.assign(step2Errors, validatedInput);
			setStep2FormErrors(error);
			setFirstnameSuccess(false);
			errorCounter++;
		}
		if (firstname.trim().length > parameters.firstnameMaxLength) {
			const validatedInput = {
				firstname: {
					error: true,
					message: `Maksymalna ilość znaków: ${parameters.firstnameMaxLength}`,
				},
			};

			const error = Object.assign(step2Errors, validatedInput);
			setStep2FormErrors(error);
			setFirstnameSuccess(false);
			errorCounter++;
		}
		if (lastname.trim() === '') {
			const validatedInput = {
				lastname: {
					error: true,
					message: 'To pole jest wymagane',
				},
			};

			const error = Object.assign(step2Errors, validatedInput);
			setStep2FormErrors(error);
			setLastnameSuccess(false);
			errorCounter++;
		}
		if (lastname.trim().length > parameters.lastnameMaxLength) {
			const validatedInput = {
				lastname: {
					error: true,
					message: `Maksymalna ilość znaków: ${parameters.lastnameMaxLength}`,
				},
			};

			const error = Object.assign(step2Errors, validatedInput);
			setStep2FormErrors(error);
			setLastnameSuccess(false);
			errorCounter++;
		}
		if (!validateEmail(email)) {
			const validatedInput = {
				email: {
					error: true,
					message: 'Nieprawidłowy format email',
				},
			};

			const error = Object.assign(step2Errors, validatedInput);
			setStep2FormErrors(error);
			setEmailSuccess(false);
			errorCounter++;
		}
		if (email.trim().length > parameters.emailMaxLength) {
			const validatedInput = {
				email: {
					error: true,
					message: `Maksymalna ilość znaków: ${parameters.emailMaxLength}`,
				},
			};

			const error = Object.assign(step2Errors, validatedInput);
			setStep2FormErrors(error);
			setEmailSuccess(false);
			errorCounter++;
		}
		if (mobile.length < 7 || mobile.length > 12) {
			const validatedInput = {
				mobile: {
					error: true,
					message: 'Nieprawidłowy format nr. telefonu',
				},
			};

			const error = Object.assign(step2Errors, validatedInput);
			setStep2FormErrors(error);
			setMobileSuccess(false);
			errorCounter++;
		} else if (!validateNumbersAndSpacesOnly(mobile)) {
			const validatedInput = {
				mobile: {
					error: true,
					message: 'Numer telefonu może zawierać tylko cyfry i spacje',
				},
			};

			const error = Object.assign(step2Errors, validatedInput);
			setStep2FormErrors(error);
			setMobileSuccess(false);
			errorCounter++;
		}
		if (workplaceCode.trim() === '') {
			const validatedInput = {
				workplaceCode: {
					error: true,
					message: 'To pole jest wymagane',
				},
			};

			const error = Object.assign(step2Errors, validatedInput);
			setStep2FormErrors(error);
			setWorkplaceCodeSuccess(false);
			errorCounter++;
		}
		if (workplaceCode.trim().length > parameters.workplaceCodeMaxLength) {
			const validatedInput = {
				workplaceCode: {
					error: true,
					message: `Maksymalna ilość znaków: ${parameters.workplaceCodeMaxLength}`,
				},
			};

			const error = Object.assign(step2Errors, validatedInput);
			setStep2FormErrors(error);
			setWorkplaceCodeSuccess(false);
			errorCounter++;
		}
		if (fileCode === '') {
			const validatedInput = {
				fileCode: {
					error: true,
					message: 'Załącz CV',
				},
			};

			const error = Object.assign(step2Errors, validatedInput);
			setStep2FormErrors(error);
			setFileCodeSuccess(false);
			errorCounter++;
		}
		if (fileCode && fileSize && fileSize > parameters.maxSize) {
			const validatedInput = {
				fileCode: {
					error: true,
					message: 'Zbyt duży rozmiar pliku - max. 5MB',
				},
			};

			const error = Object.assign(step2Errors, validatedInput);
			setStep2FormErrors(error);
			setFileCodeSuccess(false);
			errorCounter++;
		}

		if (fileCode && !parameters.mimeTypes.includes(fileType)) {
			const validatedInput = {
				fileCode: {
					error: true,
					message: 'Akceptowalne formaty: .pdf, .doc, .docx, .jpeg, .png',
				},
			};

			const error = Object.assign(step2Errors, validatedInput);
			setStep2FormErrors(error);
			setFileCodeSuccess(false);
			errorCounter++;
		}

		if (fileDescription && fileDescription.trim().length > parameters.descriptionMaxLength) {
			const validatedInput = {
				fileDescription: {
					error: true,
					message: `Maksymalna ilość znaków: ${parameters.descriptionMaxLength}`,
				},
			};

			const error = Object.assign(step2Errors, validatedInput);
			setStep2FormErrors(error);
			errorCounter++;
		}

		setStep2FormErrors((prevState) => ({ ...prevState, step2FormErrors }));
		if (errorCounter === 0) {
			setStep2FormErrors(defaultStep2FormErrors);
			setFormData({
				referenceFirstname: formData.referenceFirstname,
				referenceLastname: formData.referenceLastname,
				referenceEmail: formData.referenceEmail,
				candidateFirstname: firstname,
				candidateLastname: lastname,
				candidateEmail: email,
				candidateMobile: mobile,
				workplaceCode: workplaceCode,
				fileName: fileName,
				fileCode: fileCode,
				fileType: fileType,
				fileDescription: fileDescription ? fileDescription : '',
				agreements: formData.agreements,
			});
			setStep2Completed(true);
			setIsStep1Active(false);
			setIsStep2Active(false);
			setIsStep3Active(true);
		}
	};

	const onGoToPreviousStep = () => {
		setFormData({
			referenceFirstname: formData.referenceFirstname,
			referenceLastname: formData.referenceLastname,
			referenceEmail: formData.referenceEmail,
			candidateFirstname: firstname,
			candidateLastname: lastname,
			candidateEmail: email,
			candidateMobile: mobile,
			workplaceCode: workplaceCode,
			fileName: fileName,
			fileCode: fileCode,
			fileType: fileType,
			fileDescription: fileDescription,
			agreements: formData.agreements,
		});
		setIsStep1Active(true);
		setIsStep2Active(false);
		setIsStep3Active(false);
	};

	return (
		<Grid container className="step step-1" paddingX={4}>
			<Grid item md={12}>
				<Typography variant="h6" mb={3} className="header header-secondary">
					Formularz rekrutacyjny
				</Typography>

				<Typography className="primary-txt-style">Podaj dane osoby, którą polecasz na wybrane stanowisko pracy w MEDISEPT.</Typography>

				<Typography mb={3} mt={5} className="header header-form">
					Dane kandydata do pracy w MEDISEPT
				</Typography>

				<Grid container display={'flex'} flexDirection={'column'} alignItems={'center'}>
					<Grid item className="form-container">
						<FormControl className="inputs_containers" fullWidth>
							{step2FormErrors.firstname.error && <img className="validation-icon" src={ValidationErrorIcon} alt="validation-error" />}
							{firstnameSuccess && <img className="validation-icon" src={ValidationSuccessIcon} alt="validation-success" />}
							<div className="validaton-mark">*</div>
							<TextField
								variant="outlined"
								fullWidth
								placeholder="Imię"
								size="small"
								className={step2FormErrors.firstname.error ? 'form-element form-element__input' : 'form-element form-element--margin-bottom form-element__input'}
								value={firstname}
								onChange={handleFirstnameChange}
								error={step2FormErrors.firstname.error}
								helperText={step2FormErrors.firstname.error && step2FormErrors.firstname.message}
							/>
						</FormControl>
						<FormControl className="inputs_containers" fullWidth>
							{step2FormErrors.lastname.error && <img className="validation-icon" src={ValidationErrorIcon} alt="validation-error" />}
							{lastnameSuccess && <img className="validation-icon" src={ValidationSuccessIcon} alt="validation-success" />}
							<div className="validaton-mark">*</div>
							<TextField
								variant="outlined"
								fullWidth
								placeholder="Nazwisko"
								size="small"
								className={step2FormErrors.lastname.error ? 'form-element form-element__input' : 'form-element form-element--margin-bottom form-element__input'}
								value={lastname}
								onChange={handleLastnameChange}
								error={step2FormErrors.lastname.error}
								helperText={step2FormErrors.lastname.error && step2FormErrors.lastname.message}
							/>
						</FormControl>
						<FormControl className="inputs_containers" fullWidth>
							{step2FormErrors.email.error && <img className="validation-icon" src={ValidationErrorIcon} alt="validation-error" />}
							{emailSuccess && <img className="validation-icon" src={ValidationSuccessIcon} alt="validation-success" />}
							<div className="validaton-mark">*</div>
							<TextField
								variant="outlined"
								fullWidth
								placeholder="E-mail"
								size="small"
								className={step2FormErrors.email.error ? 'form-element form-element__input' : 'form-element form-element--margin-bottom form-element__input'}
								type="email"
								value={email}
								onChange={handleEmailChange}
								error={step2FormErrors.email.error}
								helperText={step2FormErrors.email.error && step2FormErrors.email.message}
							/>
						</FormControl>
						<FormControl className="inputs_containers" fullWidth>
							{step2FormErrors.mobile.error && <img className="validation-icon" src={ValidationErrorIcon} alt="validation-error" />}
							{mobileSuccess && <img className="validation-icon" src={ValidationSuccessIcon} alt="validation-success" />}
							<div className="validaton-mark">*</div>
							<TextField
								variant="outlined"
								fullWidth
								placeholder="Telefon"
								size="small"
								className={step2FormErrors.mobile.error ? 'form-element form-element__input' : 'form-element form-element--margin-bottom form-element__input'}
								type="text"
								value={mobile}
								onChange={handleMobileChange}
								error={step2FormErrors.mobile.error}
								helperText={step2FormErrors.mobile.error && step2FormErrors.mobile.message}
							/>
						</FormControl>
						<FormControl className="inputs_containers" fullWidth>
							{step2FormErrors.workplaceCode.error && <img className="validation-icon" src={ValidationErrorIcon} alt="validation-error" />}
							{workplaceCodeSuccess && <img className="validation-icon" src={ValidationSuccessIcon} alt="validation-success" />}
							<div className="validaton-mark">*</div>
							<TextField
								variant="outlined"
								fullWidth
								placeholder="Nazwa stanowiska lub dział"
								size="small"
								className={step2FormErrors.workplaceCode.error ? 'form-element form-element__input' : 'form-element form-element--margin-bottom form-element__input'}
								type="text"
								value={workplaceCode}
								onChange={handleWorkplaceCodeChange}
								error={step2FormErrors.workplaceCode.error}
								helperText={step2FormErrors.workplaceCode.error && step2FormErrors.workplaceCode.message}
							/>
						</FormControl>
						<FormControl className="inputs_containers" fullWidth>
							{step2FormErrors.fileCode.error && <img className="validation-icon" src={ValidationErrorIcon} alt="validation-error" />}
							{fileCodeSuccess && <img className="validation-icon" src={ValidationSuccessIcon} alt="validation-success" />}

							<div className="validaton-mark">*</div>
							<Grid container display={'flex'} flexDirection={'column'}>
								{fileName && (
									<Grid item className="form-element__file-name" md={12}>
										<img src={FileIcon} className="form-element__file-name__icon" alt="file-icon" /> {fileName}
									</Grid>
								)}
								<Grid item md={12} className={step2FormErrors.fileCode.error ? 'margin' : 'margin--margin-bottom'}>
									<input accept={parameters.mimeTypes.join(',')} style={{ display: 'none' }} id="raised-button-file" type="file" onChange={handleselectedFile} />
									<label htmlFor="raised-button-file">
										<Button component="span" style={{ width: '100%' }} className="button button--secondary">
											{!fileName && <>Załącz plik CV</>}
											{fileName && <>Zmień plik CV</>}
										</Button>
									</label>
									{step2FormErrors.fileCode.error && (
										<div className="error-msg" style={{ marginBottom: 0 }}>
											{step2FormErrors.fileCode.message}
										</div>
									)}
								</Grid>
							</Grid>
						</FormControl>
						<FormControl className="inputs_containers" fullWidth>
							<TextField
								variant="outlined"
								fullWidth
								placeholder="Opis załączonego pliku (opcjonalnie)"
								size="small"
								className="form-element form-element__input form-element__input__textarea"
								type="text"
								multiline
								rows={5}
								value={fileDescription}
								onChange={handleFileDescriptionChange}
								error={step2FormErrors.fileDescription.error}
								helperText={step2FormErrors.fileDescription.error && step2FormErrors.fileDescription.message}
							/>
						</FormControl>

						<Grid container>
							<Grid item width={'100%'} display={'flex'} justifyContent={'space-between'} marginTop={'20px'}>
								<Button className="button button--secondary button--secondary--normal" onClick={onGoToPreviousStep}>
									Poprzedni krok
								</Button>
								<Button className="button button--primary button--primary--normal" onClick={onSaveFormData}>
									Następny krok
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Step2;
