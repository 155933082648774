import axios from 'axios';
import { ParametersResponse } from '../store/Parameters/type';

const API_URL = process.env.REACT_APP_API_URL;

const getParameters = async (parameterName: string): Promise<ParametersResponse> => {
	return await axios.get(API_URL + `/api/config/parameter/${parameterName}`);
};

const exportObject = {
	getParameters,
};

export default exportObject;
