import { Grid } from '@mui/material';
import Logo from './../../../assets/logos/logo.png';
import Step1 from './partials/Step1';
import LineIcon from './../../../assets/icons/line.svg';
import { ReactComponent as EditIcon } from './../../../assets/icons/edit.svg';
import { ReactComponent as WaitingIcon } from './../../../assets/icons/waiting.svg';
import { ReactComponent as CheckedIcon } from './../../../assets/icons/checked.svg';
import Step2 from './partials/Step2';
import Step3 from './partials/Step3';
import { RegisterData } from '../../../store/Register/type';
import { Parameters } from '../../../store/Parameters/type';
import { useEffect, useState } from 'react';
import Step3Summary from './partials/Step3Summary';
import { errorHandler } from '../../../helpers/errorHandler';
import ParametersService from './../../../services/parameters.service';

const Register = () => {
	const [formData, setFormData] = useState<RegisterData>({
		referenceFirstname: '',
		referenceLastname: '',
		referenceEmail: '',
		candidateFirstname: '',
		candidateLastname: '',
		candidateEmail: '',
		candidateMobile: '',
		workplaceCode: '',
		fileName: '',
		fileCode: '',
		fileType: '',
		fileDescription: '',
		agreements: [],
	});

	const [isStep1Active, setIsStep1Active] = useState<boolean>(true);
	const [isStep2Active, setIsStep2Active] = useState<boolean>(false);
	const [isStep3Active, setIsStep3Active] = useState<boolean>(false);
	const [isStep3SummaryActive, setIsStep3SummaryActive] = useState<boolean>(false);

	const [step1Completed, setStep1Completed] = useState<boolean>(false);
	const [step2Completed, setStep2Completed] = useState<boolean>(false);
	const [step3Completed, setStep3Completed] = useState<boolean>(false);

	const [parameters, setParameters] = useState<Parameters>();

	useEffect(() => {
		const fetchParameters = async () => {
			try {
				const parametersResponse = await ParametersService.getParameters('APPLICATION_VALIDATION_PARAMETERS');
				if (parametersResponse.data) {
					setParameters(parametersResponse.data);
				}
			} catch (error) {
				errorHandler(error);
			}
		};
		fetchParameters();
	}, []);

	const renderIconState = (stepCompleted: boolean, stepActive: boolean) => {
		return (
			<>
				{!stepCompleted && !stepActive && <WaitingIcon className="step-icon" />}
				{!stepCompleted && stepActive && <EditIcon className="step-icon" />}
				{(stepCompleted && stepActive) || (stepCompleted && !stepActive && <CheckedIcon className="step-icon" />)}
				{stepCompleted && stepActive && <EditIcon className="step-icon" />}
			</>
		);
	};

	return (
		<Grid container className="register">
			<Grid container className="register-header" marginY={'20px'}>
				<img src={Logo} className="register-header__logo" alt="register-logo" />
				<Grid item className="register-header__steps">
					<div
						className={
							step1Completed || isStep1Active ? 'register-header__steps__step register-header__steps__step--completed' : 'register-header__steps__step register-header__steps__step--incompleted'
						}
					>
						{renderIconState(step1Completed, isStep1Active)}
						Krok 1
					</div>
					<div>
						<img className="register-header__steps__separator" src={LineIcon} alt="line" />
					</div>
					<div
						className={
							step2Completed || isStep2Active ? 'register-header__steps__step register-header__steps__step--completed' : 'register-header__steps__step register-header__steps__step--incompleted'
						}
					>
						{renderIconState(step2Completed, isStep2Active)}
						Krok 2
					</div>
					<div>
						<img className="register-header__steps__separator" src={LineIcon} alt="line" />
					</div>
					<div
						className={
							step3Completed || isStep3Active ? 'register-header__steps__step register-header__steps__step--completed' : 'register-header__steps__step register-header__steps__step--incompleted'
						}
					>
						{renderIconState(step3Completed, isStep3Active)}
						Krok 3
					</div>
				</Grid>
			</Grid>
			<Grid item className="bordered-container" md={12}>
				<div className="header-img"></div>
				{isStep1Active && parameters && (
					<Step1 parameters={parameters} setStep1Completed={setStep1Completed} formData={formData} setFormData={setFormData} setIsStep1Active={setIsStep1Active} setIsStep2Active={setIsStep2Active} />
				)}
				{isStep2Active && parameters && (
					<Step2
						parameters={parameters}
						setStep2Completed={setStep2Completed}
						formData={formData}
						setFormData={setFormData}
						setIsStep1Active={setIsStep1Active}
						setIsStep2Active={setIsStep2Active}
						setIsStep3Active={setIsStep3Active}
					/>
				)}
				{isStep3Active && (
					<Step3 setStep3Completed={setStep3Completed} formData={formData} setIsStep2Active={setIsStep2Active} setIsStep3Active={setIsStep3Active} setIsStep3SummaryActive={setIsStep3SummaryActive} />
				)}
				{isStep3SummaryActive && (
					<Step3Summary setStep1Completed={setStep1Completed} setStep2Completed={setStep3Completed} setStep3Completed={setStep3Completed} setIsStep3SummaryActive={setIsStep3SummaryActive} />
				)}
			</Grid>
			{!isStep3Active && !isStep3SummaryActive && (
				<Grid item md={12} className="required-info">
					*- Pole wymagane
				</Grid>
			)}
		</Grid>
	);
};

export default Register;
