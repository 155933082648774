import { Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Step4Revoked = () => {
	const navigate = useNavigate();
	const toHomePage = () => {
		navigate('/');
	};
	return (
		<Grid container className="step step-4" paddingX={4}>
			<Grid item md={12}>
				<Typography variant="h5" mb={3} className="header header-secondary">
					Usuwanie danych
				</Typography>

				<Typography className="primary-txt-style" mb={3}>
					Twoje dane zostały usunięte z bazy Systemu Poleceń MEDISEPT.
				</Typography>
				<Typography className="primary-txt-style">Jeśli chcesz dołączyć do zespołu MEDISEPT wejdź na naszą stronę:</Typography>
				<Typography className="primary-txt-style" mb={3}>
					<a href="https://medisept.pl/kariera/">https://medisept.pl/kariera/</a>
				</Typography>
				<Button className="button button--primary button--primary--wide" onClick={toHomePage}>
					Przejdź do strony głównej
				</Button>
			</Grid>
		</Grid>
	);
};
export default Step4Revoked;
