import { Grid, Typography } from '@mui/material';
import Logo from './../../../assets/logos/logo.png';

const PageNotFound = () => {
	return (
		<Grid container className="home">
			<Grid container className="home-header" marginY={'20px'}>
				<img src={Logo} className="home-header__logo" alt="logo" />
			</Grid>
			<Grid item className="bordered-container" md={12}>
				<div className="header-img"></div>
				<Typography variant="h5" mb={3} className="header header-primary">
					Błąd 404
				</Typography>

				<Typography mb={2} className="primary-txt-style">
					Strona nie istnieje
				</Typography>
			</Grid>
		</Grid>
	);
};

export default PageNotFound;
