import './App.css';
import './scss/style.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './components/Pages/Home/Home';
import Register from './components/Pages/Register/Register';
import Confirm from './components/Pages/Confirm/Confirm';
import PageNotFound from './components/Pages/PageNotFound/PageNotFound';
import { Grid } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
	return (
		<Grid className="pages-container">
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/register" element={<Register />} />
					<Route path="/confirm/:code" element={<Confirm />} />
					<Route path="*" element={<PageNotFound />} />
				</Routes>
			</BrowserRouter>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="colored"
				style={{ whiteSpace: 'pre-line' }}
			/>
		</Grid>
	);
}

export default App;
