import { Button, Grid, Typography } from '@mui/material';
import { Email } from 'hidden-from-bots-react';
import { useNavigate } from 'react-router-dom';

const Step4Confirmed = () => {
	const navigate = useNavigate();
	const toHomePage = () => {
		navigate('/');
	};
	return (
		<Grid container className="step step-4" paddingX={4}>
			<Grid item md={12}>
				<Typography variant="h5" mb={3} className="header header-primary">
					Dziękujemy!
				</Typography>

				<Typography className="primary-txt-style">Twoja kandydatura została zarejestrowana w bazie Systemu Poleceń MEDISEPT.</Typography>
				<Typography className="primary-txt-style" mb={3}>
					Skontaktujemy się z Tobą, jeśli Twój profil okaże się zgodny z naszymi potrzebami rekrutacyjnymi.
				</Typography>
				<Typography className="primary-txt-style">W razie dodatkowych pytań skontaktuj się z nami wysyłając wiadomość na adres</Typography>
				<Typography className="primary-txt-style" mb={3}>
					<Email className="email email--recruitment" email="rekrutacja@medisept.pl">
						<span style={{ display: 'none' }}>Contact email</span>
					</Email>
				</Typography>
				<Button className="button button--primary button--primary--wide" onClick={toHomePage}>
					Przejdź do strony głównej
				</Button>
			</Grid>
		</Grid>
	);
};
export default Step4Confirmed;
