import { Dialog, DialogContent, DialogContentText, Typography } from '@mui/material';
import React from 'react';

type RevokeApplicationDialogProps = {
	text: string;
	open: boolean;
	handleClose: (refresh: boolean) => void;
};

const RevokeApplicationDialog: React.FunctionComponent<RevokeApplicationDialogProps> = ({ text, open, handleClose }) => {
	return (
		<Dialog
			fullWidth
			maxWidth="sm"
			open={open}
			className="dialog dialog--revoke"
			onClose={() => {
				handleClose(true);
			}}
		>
			<DialogContent>
				<DialogContentText textAlign={'center'}>
					<Typography margin={3} className="primary-txt-style" textAlign={'left'} dangerouslySetInnerHTML={{ __html: text }}></Typography>
				</DialogContentText>
			</DialogContent>
		</Dialog>
	);
};

export default RevokeApplicationDialog;
