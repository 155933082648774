import { Button, Grid, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';

type Step3SummaryProps = {
	setStep1Completed: React.Dispatch<React.SetStateAction<boolean>>;
	setStep2Completed: React.Dispatch<React.SetStateAction<boolean>>;
	setStep3Completed: React.Dispatch<React.SetStateAction<boolean>>;
	setIsStep3SummaryActive: React.Dispatch<React.SetStateAction<boolean>>;
};
const Step3Summary: FunctionComponent<Step3SummaryProps> = ({ setStep1Completed, setStep2Completed, setStep3Completed, setIsStep3SummaryActive }) => {
	const navigate = useNavigate();

	const onCloseForm = () => {
		setStep1Completed(false);
		setStep2Completed(false);
		setStep3Completed(false);
		setIsStep3SummaryActive(false);
		navigate('/');
	};

	return (
		<Grid container className="step step-1" paddingX={4}>
			<Grid item md={12}>
				<Typography variant="h5" mb={3} className="header header-primary">
					Podsumowanie
				</Typography>

				<Typography className="primary-txt-style" mb={2}>
					Dziękujemy za zgłoszenie!
				</Typography>
				<Typography className="primary-txt-style">Po zaakceptowaniu danych przez kandydata, jego CV trafi do naszej bazy.</Typography>
				<Typography className="primary-txt-style">Skontaktujemy się z nim, jeśli jego profil okaże się zgodny z naszymi potrzebami rekrutacyjnymi,</Typography>
				<Grid container marginTop={3}>
					<Grid item width={'100%'} display={'flex'} justifyContent={'center'}>
						<Button className="button button--primary button--primary--normal" onClick={onCloseForm}>
							OK
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Step3Summary;
