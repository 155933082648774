import { Button, Grid, Typography } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import ConfirmService from './../../../../services/confirm.service';
import { errorHandler } from '../../../../helpers/errorHandler';
type Step1Props = {
	setStep1Completed: React.Dispatch<React.SetStateAction<boolean>>;
	code: string;
};
const Step3Summary: FunctionComponent<Step1Props> = ({ setStep1Completed, code }) => {
	const [btnDisabled, setBtnDisabled] = useState<boolean>(false);
	const onSendCode = async () => {
		try {
			setBtnDisabled(true);
			const sendCodeResponse = await ConfirmService.sendConfirmationCodeForApp(code);
			if (sendCodeResponse.data.success) {
				setStep1Completed(true);
			}
		} catch (error) {
			errorHandler(error);
		} finally {
			setBtnDisabled(false);
		}
	};

	return (
		<Grid container className="step step-1" paddingX={4}>
			<Grid item md={12}>
				<Typography variant="h5" mb={3} className="header header-secondary">
					Weryfikacja
				</Typography>

				<Typography className="primary-txt-style" mb={2}>
					Potwierdź swoją tożsamość, aby zobaczyć i zweryfikować dane przesłane przez polecającego w formularzu rekrutacyjnym.
				</Typography>
				<Typography className="primary-txt-style"> Kliknij poniższy przycisk, aby uzyskać jednorazowy kod, który zostanie wysłany na Twój adres e-mail.</Typography>
				<Grid container marginTop={3}>
					<Grid item width={'100%'} display={'flex'} justifyContent={'center'}>
						<Button className="button button--secondary button--secondary--normal" onClick={onSendCode} disabled={btnDisabled} style={{ opacity: btnDisabled ? '0.3' : '1' }}>
							Wyślij kod
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Step3Summary;
