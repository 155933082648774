export const convertBase64 = (file: any) => {
	return new Promise((resolve, reject) => {
		const fileReader = new FileReader();
		fileReader.readAsDataURL(file);
		fileReader.onload = () => {
			resolve(fileReader.result as string);
		};
		fileReader.onerror = (error) => {
			reject(error);
		};
	});
};

export const validateEmail = (email: string) => {
	const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
	const result: boolean = expression.test(email);

	return result;
};

export const validateNumbersAndSpacesOnly = (phone: string) => {
	const expression: RegExp = /^[\d ]*$/gi;
	const result: boolean = expression.test(phone);

	return result;
};
