import { CircularProgress, Grid } from '@mui/material';
import Logo from './../../../assets/logos/logo.png';
import Step1 from './partials/Step1';
import Step2 from './partials/Step2';
import Step3 from './partials/Step3';
import Step4Confirmed from './partials/Step4Confirmed';
import Step4Revoked from './partials/Step4Revoked';
import ConfirmService from './../../../services/confirm.service';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import StepError from './partials/StepError';
import { Parameters } from '../../../store/Parameters/type';
import ParametersService from './../../../services/parameters.service';
import { errorHandler } from '../../../helpers/errorHandler';
import { Email } from 'hidden-from-bots-react';

const Confirm = () => {
	const { code } = useParams();
	const [step1Completed, setStep1Completed] = useState<boolean>(false);
	const [step2Completed, setStep2Completed] = useState<boolean>(false);
	const [step3Completed, setStep3Completed] = useState<boolean>(false);
	const [applicationRevoked, setApplicationRevoked] = useState<boolean>(false);
	const [isValidCode, setIsValidCode] = useState<boolean>(false);

	const [parameters, setParameters] = useState<Parameters>();

	const [loading, setLoading] = useState<boolean>(true);
	useEffect(() => {
		const checkCode = async () => {
			try {
				if (code) {
					const checkCodeResponse = await ConfirmService.checkCode(code);
					if (checkCodeResponse.data.success) {
						setIsValidCode(true);
					}
					if (!checkCodeResponse.data.success) {
						setIsValidCode(false);
					}
				}
				if (!code) {
					setIsValidCode(false);
				}
			} catch (error) {
				setIsValidCode(false);
			} finally {
				setLoading(false);
			}
		};
		checkCode();
	}, [code]);

	useEffect(() => {
		const fetchParameters = async () => {
			try {
				const parametersResponse = await ParametersService.getParameters('APPLICATION_VALIDATION_PARAMETERS');
				if (parametersResponse.data) {
					setParameters(parametersResponse.data);
				}
			} catch (error) {
				errorHandler(error);
			}
		};
		fetchParameters();
	}, []);
	return (
		<Grid container className="confirm">
			<Grid container className="confirm-header" marginY={'20px'}>
				<img src={Logo} className="confirm-header__logo" alt="confirm-logo" />
			</Grid>
			<Grid item className="bordered-container" md={12}>
				<div className="header-img"></div>
				{loading && <CircularProgress />}
				{!loading && (
					<>
						{code && isValidCode && !step1Completed && <Step1 setStep1Completed={setStep1Completed} code={code} />}
						{code && isValidCode && step1Completed && !step2Completed && <Step2 setStep2Completed={setStep2Completed} code={code} />}
						{code && parameters && isValidCode && step1Completed && step2Completed && !step3Completed && (
							<Step3 setApplicationRevoked={setApplicationRevoked} setStep3Completed={setStep3Completed} code={code} parameters={parameters} />
						)}
						{code && step1Completed && step2Completed && step3Completed && !applicationRevoked && <Step4Confirmed />}
						{code && step1Completed && step2Completed && step3Completed && applicationRevoked && <Step4Revoked />}
						{code && !isValidCode && !step1Completed && !step2Completed && !step3Completed && <StepError />}
					</>
				)}
				{isValidCode && !step2Completed && (
					<Grid item md={12} className="required-info required-info--support" textAlign={'center'} mt={3}>
						W razie problemów skontaktuj się z nami wysyłając maila na adres:{' '}
						<Email className="email email--support" email="support@tcreate.pl">
							<span style={{ display: 'none' }}>Contact email</span>
						</Email>
					</Grid>
				)}
			</Grid>
		</Grid>
	);
};

export default Confirm;
