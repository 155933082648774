import { Dialog, DialogContent, DialogContentText, DialogActions, Stack, Typography, Button } from '@mui/material';
import React, { useState } from 'react';
import Logo from './../../../../assets/logos/logo.png';
import ConfirmService from './../../../../services/confirm.service';
import { errorHandler } from '../../../../helpers/errorHandler';

type RevokeApplicationDialogProps = {
	code: string;
	open: boolean;
	handleClose: (refresh: boolean) => void;
	setStep3Completed: React.Dispatch<React.SetStateAction<boolean>>;
	setApplicationRevoked: React.Dispatch<React.SetStateAction<boolean>>;
};

const RevokeApplicationDialog: React.FunctionComponent<RevokeApplicationDialogProps> = ({ code, open, handleClose, setStep3Completed, setApplicationRevoked }) => {
	const [btnDisabled, setBtnDisabled] = useState<boolean>(false);
	const onSave = async () => {
		try {
			setBtnDisabled(true);
			const revokeResponse = await ConfirmService.applicationRevoke(code);
			if (revokeResponse.data.success) {
				handleClose(true);
				setStep3Completed(true);
				setApplicationRevoked(true);
			}
		} catch (error) {
			errorHandler(error);
		} finally {
			setBtnDisabled(false);
		}
	};

	return (
		<Dialog
			fullWidth
			maxWidth="sm"
			open={open}
			className="dialog dialog--revoke"
			onClose={(event, reason) => {
				if (reason && reason === 'backdropClick') return;
				handleClose(false);
			}}
		>
			<DialogContent>
				<DialogContentText textAlign={'center'}>
					<Stack display={'flex'} alignItems={'center'} width={'100%'} marginBottom={'30px'}>
						<img src={Logo} className="dialog__logo" alt="dialog-logo" />
					</Stack>
					<Typography variant="h5" mb={3} className="header header-secondary">
						Usuwanie danych
					</Typography>
					<Typography className="primary-txt-style">Twoje dane zostaną usunięte z systemu MEDISEPT.</Typography>
					<Typography className="primary-txt-style">Czy na pewno chcesz zrezygnować z udziału w rekrutacji?</Typography>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Stack flexDirection="row" justifyContent="space-between" width="100%">
					<Button
						onClick={() => {
							handleClose(true);
						}}
						className="button button--primary button--primary--dialog-size"
					>
						Powrót
					</Button>
					<Button className="button button--tertiary button--tertiary--dialog-size" onClick={onSave} disabled={btnDisabled} style={{ opacity: btnDisabled ? '0.3' : '1' }}>
						Tak, usuń moje dane
					</Button>
				</Stack>
			</DialogActions>
		</Dialog>
	);
};

export default RevokeApplicationDialog;
