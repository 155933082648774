import { Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormHelperText, Grid, TextField, Typography } from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import { FormError } from '../../../../store/types';
import { ApplicationConfirmParams, Step3Errors } from '../../../../store/Confirm/type';
import EditIcon from './../../../../assets/icons/edit-form.svg';
import FileIcon from './../../../../assets/icons/file.svg';

import { convertBase64, validateEmail, validateNumbersAndSpacesOnly } from '../../../../helpers/utils';
import { AgreementConfirm } from '../../../../store/Confirm/type';
import { Agreement } from '../../../../store/Agreements/type';
import { errorHandler } from '../../../../helpers/errorHandler';
import AgreementsService from './../../../../services/agreements.service';
import ConfirmService from './../../../../services/confirm.service';
import React from 'react';
import RevokeApplicationDialog from './RevokeApplicationDialog';
import AgreementDialog from '../../../shared/AgreementDialog/AgreementDialog';
import { Parameters } from '../../../../store/Parameters/type';

type Step3Props = {
	parameters: Parameters;
	setApplicationRevoked: React.Dispatch<React.SetStateAction<boolean>>;
	setStep3Completed: React.Dispatch<React.SetStateAction<boolean>>;
	code: string;
};
const Step3: FunctionComponent<Step3Props> = ({ parameters, setApplicationRevoked, setStep3Completed, code }) => {
	const [applicationUuid, setApplicationUuid] = useState<string>('');
	const [applicationId, setApplicationId] = useState<string>('');
	const [referenceFirstname, setReferenceFirstname] = useState<string>('');
	const [referenceLastname, setReferenceLastname] = useState<string>('');
	const [firstname, setFirstname] = useState<string>('');
	const [lastname, setLastname] = useState<string>('');
	const [email, setEmail] = useState<string>('');
	const [mobile, setMobile] = useState<string>('');
	const [workplaceCode, setWorkplaceCode] = useState<string>('');

	const [fileName, setFileName] = useState<string>('');
	// const [fileCode, setFileCode] = useState<string | File | Blob | ProgressEvent<FileReader>>('');
	const [fileCode, setFileCode] = useState<string>('');
	const [fileSize, setFileSize] = useState<number | null>(null);
	const [fileType, setFileType] = useState<string>('');

	const [newFile, setNewFile] = useState<boolean>(false);

	const [agreementsList, setAgreementsList] = useState<Agreement[]>([]);
	const [agreements, setAgreements] = useState<AgreementConfirm[]>([]);

	const [dialogAgreementTxt, setDialogAgreementTxt] = useState<string>('');
	const [agreementsDialogOpen, setAgreementsDialogOpen] = useState(false);

	const [fileDescription, setFileDescription] = useState<string | null>('');

	const [preloader, setPreloader] = useState<boolean>(false);

	const [confirmApplicationBtn, setConfirmApplicationBtn] = useState<boolean>(false);

	const [openRevokeApplicationDialog, setRevokeApplicationDialogOpen] = useState(false);

	const defaultError: FormError = {
		error: false,
		message: '',
	};

	const defaultStep3FormErrors: Step3Errors = {
		firstname: defaultError,
		lastname: defaultError,
		email: defaultError,
		mobile: defaultError,
		workplaceCode: defaultError,
		fileName: defaultError,
		fileCode: defaultError,
		fileDescription: defaultError,
		agreements: defaultError,
	};

	const [step3FormErrors, setStep3FormErrors] = useState<Step3Errors>(defaultStep3FormErrors);

	const handleRevokeApplicationDialogClickOpen = () => {
		setRevokeApplicationDialogOpen(true);
	};

	const handleRevokeApplicationDialogClickClose = () => {
		setRevokeApplicationDialogOpen(false);
	};
	const handleAgreementsDialogClickOpen = (txt: string | null) => {
		setDialogAgreementTxt(txt ? txt : '');
		setAgreementsDialogOpen(true);
	};

	const handleAgreementsDialogClickClose = () => {
		setAgreementsDialogOpen(false);
	};

	const handleFirstnameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFirstname(event.target.value);
	};
	const handleLastnameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLastname(event.target.value);
	};
	const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value);
	};
	const handleMobileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setMobile(event.target.value);
	};
	const handleWorkplaceCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setWorkplaceCode(event.target.value);
	};
	const handleAgreementChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
		setAgreements(agreements.map((agreement, currentIndex) => (currentIndex + 1 === index ? { ...agreement, isAccepted: event.target.checked } : agreement)));
	};

	const handleSelectedFile = async (event: any) => {
		const file = event.target.files[0];
		if (file) {
			setFileName(file.name);
			setFileSize(file.size);
			setFileType(file.type);
			const base64 = await convertBase64(file);
			setFileCode((base64 as string).split('base64,')[1]);
			setNewFile(true);
		}
	};

	const handleFileDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFileDescription(event.target.value);
	};
	const downloadApplicationFile = async (mimeType: string) => {
		try {
			const applicationCode = await ConfirmService.getApplicationFile(code);
			const url = window.URL.createObjectURL(new Blob([applicationCode.data], { type: mimeType }));

			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `kandydatura_${code}`);
			document.body.appendChild(link);
			link.click();
		} catch (error) {
			errorHandler(error);
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			setPreloader(true);
			try {
				const agreementsListReponse = await AgreementsService.getAgreementsList('CDAT', null);
				const applicationPreviewResponse = await ConfirmService.getApplicationPreview(code);

				if (agreementsListReponse.data.agreements && applicationPreviewResponse.data) {
					setAgreementsList(agreementsListReponse.data.agreements);
					setApplicationUuid(code);
					setApplicationId(applicationPreviewResponse.data.applicationId);
					setReferenceFirstname(applicationPreviewResponse.data.referenceFirstname);
					setReferenceLastname(applicationPreviewResponse.data.referenceLastname);
					setFirstname(applicationPreviewResponse.data.candidateFirstname);
					setLastname(applicationPreviewResponse.data.candidateLastname);
					setEmail(applicationPreviewResponse.data.candidateEmail);
					setMobile(applicationPreviewResponse.data.candidateMobile);
					setWorkplaceCode(applicationPreviewResponse.data.workplaceCode);
					setFileName(applicationPreviewResponse.data.fileName);
					setFileCode(applicationPreviewResponse.data.fileContent);
					setFileSize(applicationPreviewResponse.data.fileSize);
					setFileType(applicationPreviewResponse.data.fileMimeType);
					setFileDescription(applicationPreviewResponse.data.description ? applicationPreviewResponse.data.description : '');
					setPreloader(false);
				}
			} catch (error) {
				errorHandler(error);
			}
		};
		fetchData();
	}, [code]);
	useEffect(() => {
		const agreementsValues = agreementsList?.map((agreement, index) => ({
			id: agreement.id,
			indexForAgreementsCheck: index + 1,
			description: agreement.description,
			shortDescription: agreement.shortDescription,
			linkLabel: agreement.linkLabel,
			url: agreement.url,
			isRequired: agreement.isRequired,
			isAccepted: agreement.isAccepted,
		}));
		setAgreements(agreementsValues);
	}, [agreementsList]);

	const onConfirm = async () => {
		setStep3FormErrors(defaultStep3FormErrors);
		const step3Errors = defaultStep3FormErrors;

		let errorCounter = 0;
		if (firstname.trim() === '') {
			errorCounter++;
			const validatedInput = {
				firstname: {
					error: true,
					message: 'To pole jest wymagane',
				},
			};

			const error = Object.assign(step3Errors, validatedInput);
			setStep3FormErrors(error);
		}
		if (firstname.trim().length > parameters.firstnameMaxLength) {
			const validatedInput = {
				firstname: {
					error: true,
					message: `Maksymalna ilość znaków: ${parameters.firstnameMaxLength}`,
				},
			};

			const error = Object.assign(step3Errors, validatedInput);
			setStep3FormErrors(error);
			errorCounter++;
		}

		if (lastname.trim() === '') {
			errorCounter++;
			const validatedInput = {
				lastname: {
					error: true,
					message: 'To pole jest wymagane',
				},
			};

			const error = Object.assign(step3Errors, validatedInput);
			setStep3FormErrors(error);
		}
		if (lastname.trim().length > parameters.lastnameMaxLength) {
			const validatedInput = {
				lastname: {
					error: true,
					message: `Maksymalna ilość znaków: ${parameters.lastnameMaxLength}`,
				},
			};

			const error = Object.assign(step3Errors, validatedInput);
			setStep3FormErrors(error);
			errorCounter++;
		}
		if (!validateEmail(email)) {
			errorCounter++;
			const validatedInput = {
				email: {
					error: true,
					message: 'Nieprawidłowy format email',
				},
			};

			const error = Object.assign(step3Errors, validatedInput);
			setStep3FormErrors(error);
		}
		if (email.trim().length > parameters.emailMaxLength) {
			const validatedInput = {
				email: {
					error: true,
					message: `Maksymalna ilość znaków: ${parameters.emailMaxLength}`,
				},
			};

			const error = Object.assign(step3Errors, validatedInput);
			setStep3FormErrors(error);
			errorCounter++;
		}

		if (mobile.length < 7 || mobile.length > 12) {
			errorCounter++;
			const validatedInput = {
				mobile: {
					error: true,
					message: 'Nieprawidłowy format nr. telefonu',
				},
			};

			const error = Object.assign(step3Errors, validatedInput);
			setStep3FormErrors(error);
		} else if (!validateNumbersAndSpacesOnly(mobile)) {
			errorCounter++;
			const validatedInput = {
				mobile: {
					error: true,
					message: 'Numer telefonu może zawierać tylko cyfry i spacje',
				},
			};

			const error = Object.assign(step3Errors, validatedInput);
			setStep3FormErrors(error);
		}
		if (workplaceCode === '') {
			errorCounter++;
			const validatedInput = {
				workplaceCode: {
					error: true,
					message: 'To pole jest wymagane',
				},
			};

			const error = Object.assign(step3Errors, validatedInput);
			setStep3FormErrors(error);
		}
		if (workplaceCode.trim().length > parameters.workplaceCodeMaxLength) {
			const validatedInput = {
				workplaceCode: {
					error: true,
					message: `Maksymalna ilość znaków: ${parameters.workplaceCodeMaxLength}`,
				},
			};

			const error = Object.assign(step3Errors, validatedInput);
			setStep3FormErrors(error);
			errorCounter++;
		}
		if (fileCode === '') {
			errorCounter++;
			const validatedInput = {
				fileCode: {
					error: true,
					message: 'Załącz CV',
				},
			};

			const error = Object.assign(step3Errors, validatedInput);
			setStep3FormErrors(error);
		}

		if (fileCode && fileSize && fileSize > parameters.maxSize) {
			const validatedInput = {
				fileCode: {
					error: true,
					message: 'Zbyt duży rozmiar pliku - max. 5MB',
				},
			};

			const error = Object.assign(step3Errors, validatedInput);
			setStep3FormErrors(error);
			errorCounter++;
		}

		if (fileCode && !parameters.mimeTypes.includes(fileType)) {
			const validatedInput = {
				fileCode: {
					error: true,
					message: 'Akceptowalne formaty: .pdf, .doc, .docx, .jpeg, .png',
				},
			};

			const error = Object.assign(step3Errors, validatedInput);
			setStep3FormErrors(error);
			errorCounter++;
		}

		if (fileDescription && fileDescription.trim().length > parameters.descriptionMaxLength) {
			const validatedInput = {
				fileDescription: {
					error: true,
					message: `Maksymalna ilość znaków: ${parameters.descriptionMaxLength}`,
				},
			};

			const error = Object.assign(step3Errors, validatedInput);
			setStep3FormErrors(error);
			errorCounter++;
		}

		const requiredAgreements = agreementsList ? agreementsList.filter((agreement) => agreement.isRequired).length : 0;
		const agreementsChecked = agreements ? agreements.filter((agreement) => agreement.isAccepted && agreement.isRequired).length : 0;

		const requiredAndCheckedAgreementsAreEqual = requiredAgreements === agreementsChecked;
		if (!requiredAndCheckedAgreementsAreEqual) {
			errorCounter++;
			const validatedInput = {
				agreements: {
					error: true,
					message: 'Zaznacz wymagane zgody',
				},
			};

			const error = Object.assign(step3Errors, validatedInput);
			setStep3FormErrors(error);
		}
		setStep3FormErrors((prevState) => ({ ...prevState, step3FormErrors }));
		if (errorCounter === 0) {
			const formParams: ApplicationConfirmParams = {
				applicationUuid: applicationUuid,
				revokeApplication: false,
				candidateFirstname: firstname,
				candidateLastname: lastname,
				candidateEmail: email,
				candidateMobile: mobile,
				workplaceCode: workplaceCode,
				filename: fileName,
				fileContent: fileCode,
				description: fileDescription && fileDescription.length > 0 ? fileDescription : null,
				agreements: agreements.map((agreement) => ({ id: agreement.id, isAccepted: agreement.isAccepted })),
			};
			try {
				setConfirmApplicationBtn(true);
				const confirmApplicationResponse = await ConfirmService.applicationConfirm(formParams);

				if (confirmApplicationResponse.data.success) {
					setStep3Completed(true);
				}
			} catch (error) {
				errorHandler(error);
			} finally {
				setConfirmApplicationBtn(false);
			}
		}
	};

	return (
		<Grid container className="step step-3" paddingX={4}>
			{preloader && (
				<Grid item>
					<CircularProgress color="inherit" />
				</Grid>
			)}
			{!preloader && (
				<Grid item md={12}>
					<Typography variant="h5" mb={3} className="header header-secondary">
						Zgłoszenie kandydata
					</Typography>

					<Typography className="primary-txt-style" mb={2}>
						Twoja kandydatura została zgłoszona na wskazane poniżej stanowisko / dział w MEDISEPT.
					</Typography>
					<Typography className="primary-txt-style">
						OSOBA ZGŁASZAJĄCA: <span className="reference-data">{referenceFirstname + ' ' + referenceLastname}</span>
					</Typography>
					<Typography className="primary-txt-style" mb={3}>
						KOD ZGŁOSZENIA: <span className="reference-data">{applicationId}</span>
					</Typography>

					<Typography className="primary-txt-style" mb={3}>
						Prosimy o weryfikację i ewentualną korektę poniższych danych oraz zgodę na przetwarzanie danych osobowych przez MEDISEPT.
					</Typography>

					<Typography className="primary-txt-style" mb={3}>
						Jeśli nie wyrażasz zgody na ich przetwarzanie lub nie chcesz brać udziału w rekrutacji kliknij USUŃ DANE
					</Typography>

					<Typography className="primary-txt-style" mb={3}>
						Brak potwierdzenia danych w ciągu 30 dni od otrzymania maila skutkuje usunięciem danych z naszego systemu.
					</Typography>
					<Grid container display={'flex'} flexDirection={'column'} alignItems={'center'}>
						<Grid item className="form-container">
							<FormControl className="inputs_containers" fullWidth>
								<img src={EditIcon} alt="Edit-icon" className="edit-icon" />
								<TextField
									variant="outlined"
									fullWidth
									placeholder="Imię"
									size="small"
									className={step3FormErrors.firstname.error ? 'form-element form-element__input' : 'form-element form-element--margin-bottom form-element__input'}
									value={firstname}
									onChange={handleFirstnameChange}
									error={step3FormErrors.firstname.error}
									helperText={step3FormErrors.firstname.error && step3FormErrors.firstname.message}
								/>
							</FormControl>
							<FormControl className="inputs_containers" fullWidth>
								<img src={EditIcon} alt="Edit-icon" className="edit-icon" />
								<TextField
									variant="outlined"
									fullWidth
									placeholder="Nazwisko"
									size="small"
									className={step3FormErrors.lastname.error ? 'form-element form-element__input' : 'form-element form-element--margin-bottom form-element__input'}
									value={lastname}
									onChange={handleLastnameChange}
									error={step3FormErrors.lastname.error}
									helperText={step3FormErrors.lastname.error && step3FormErrors.lastname.message}
								/>
							</FormControl>
							<FormControl className="inputs_containers" fullWidth>
								<img src={EditIcon} alt="Edit-icon" className="edit-icon" />
								<TextField
									variant="outlined"
									fullWidth
									placeholder="E-mail"
									size="small"
									className={step3FormErrors.email.error ? 'form-element form-element__input' : 'form-element form-element--margin-bottom form-element__input'}
									type="email"
									value={email}
									onChange={handleEmailChange}
									error={step3FormErrors.email.error}
									helperText={step3FormErrors.email.error && step3FormErrors.email.message}
								/>
							</FormControl>
							<FormControl className="inputs_containers" fullWidth>
								<img src={EditIcon} alt="Edit-icon" className="edit-icon" />
								<TextField
									variant="outlined"
									fullWidth
									placeholder="Telefon"
									size="small"
									className={step3FormErrors.mobile.error ? 'form-element form-element__input' : 'form-element form-element--margin-bottom form-element__input'}
									type="text"
									value={mobile}
									onChange={handleMobileChange}
									error={step3FormErrors.mobile.error}
									helperText={step3FormErrors.mobile.error && step3FormErrors.mobile.message}
								/>
							</FormControl>
							<FormControl className="inputs_containers" fullWidth>
								<img src={EditIcon} alt="Edit-icon" className="edit-icon" />
								<TextField
									variant="outlined"
									fullWidth
									placeholder="Nazwa stanowiska lub dział"
									size="small"
									className={step3FormErrors.workplaceCode.error ? 'form-element form-element__input' : 'form-element form-element--margin-bottom form-element__input'}
									type="text"
									value={workplaceCode}
									onChange={handleWorkplaceCodeChange}
									error={step3FormErrors.workplaceCode.error}
									helperText={step3FormErrors.workplaceCode.error && step3FormErrors.workplaceCode.message}
								/>
							</FormControl>
							<FormControl className="inputs_containers" fullWidth>
								<Grid container display={'flex'} flexDirection={'column'}>
									{!newFile && (
										<div style={{ cursor: 'pointer' }} onClick={() => downloadApplicationFile(fileType)}>
											{fileName && (
												<Grid item className="form-element__file-name" md={12}>
													<img src={FileIcon} className="form-element__file-name__icon" alt="file-icon" /> {fileName}
												</Grid>
											)}
											<Grid item className="form-element__file-hint" md={12}>
												Zobacz dołączony plik lub zmień go poniżej.
											</Grid>
										</div>
									)}
									{newFile && (
										<>
											{fileName && (
												<Grid item className="form-element__file-name" md={12}>
													<img src={FileIcon} className="form-element__file-name__icon" alt="file-icon" /> {fileName}
												</Grid>
											)}
										</>
									)}
									<Grid item md={12} className={step3FormErrors.fileCode.error ? 'margin' : 'margin--margin-bottom'}>
										<input
											accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,application/pdf, image/jpeg, image/png"
											style={{ display: 'none' }}
											id="raised-button-file"
											type="file"
											onChange={handleSelectedFile}
										/>
										<label htmlFor="raised-button-file">
											<Button component="span" style={{ width: '100%' }} className="button button--secondary">
												{!fileName && <>Załącz plik CV</>}
												{fileName && <>Zmień plik CV</>}
											</Button>
										</label>
										{step3FormErrors.fileCode.error && (
											<div className="error-msg" style={{ marginBottom: 0 }}>
												{step3FormErrors.fileCode.message}
											</div>
										)}
									</Grid>
								</Grid>
							</FormControl>
							<FormControl className="inputs_containers" sx={{ marginBottom: '20px' }} fullWidth>
								<img src={EditIcon} alt="Edit-icon" className="edit-icon" />
								<TextField
									variant="outlined"
									fullWidth
									placeholder="Opis załączonego pliku (opcjonalnie)"
									size="small"
									className="form-element form-element__input form-element__input__textarea"
									type="text"
									multiline
									rows={5}
									value={fileDescription}
									onChange={handleFileDescriptionChange}
									error={step3FormErrors.fileDescription.error}
									helperText={step3FormErrors.fileDescription.error && step3FormErrors.fileDescription.message}
								/>
							</FormControl>
							<div style={{ textAlign: 'left' }}>
								{agreements?.map((agreement) => (
									<FormControl key={agreement.id} className="inputs_containers">
										{agreement.isRequired && <div className="validaton-mark">*</div>}
										<FormControlLabel
											className={agreement.description ? 'custom-checkbox' : 'custom-checkbox margin--margin-bottom'}
											control={<Checkbox checked={agreement.isAccepted} onChange={(e) => handleAgreementChange(e, agreement.indexForAgreementsCheck)} />}
											label={
												<React.Fragment>
													{agreement.shortDescription}{' '}
													{agreement.url && (
														<a href={agreement.url} target="_blank" rel="noreferrer">
															{agreement.linkLabel}
														</a>
													)}
												</React.Fragment>
											}
										/>
										{agreement.description && (
											<FormHelperText className="show-more">
												<span
													onClick={() => {
														handleAgreementsDialogClickOpen(agreement.description);
													}}
												>
													pokaż więcej
												</span>
											</FormHelperText>
										)}
									</FormControl>
								))}
							</div>

							{step3FormErrors.agreements.error && <div className="error-msg">{step3FormErrors.agreements.message}</div>}

							<Grid container>
								<Grid item width={'100%'} display={'flex'} justifyContent={'space-between'} marginTop={'20px'}>
									<Button className="button button--secondary button--secondary--normal" onClick={handleRevokeApplicationDialogClickOpen}>
										Usuń dane
									</Button>
									<Button className="button button--primary button--primary--normal" onClick={onConfirm} disabled={confirmApplicationBtn} style={{ opacity: confirmApplicationBtn ? '0.3' : '1' }}>
										Potwierdzam
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			)}
			<RevokeApplicationDialog
				code={code}
				open={openRevokeApplicationDialog}
				setApplicationRevoked={setApplicationRevoked}
				handleClose={handleRevokeApplicationDialogClickClose}
				setStep3Completed={setStep3Completed}
			/>
			<AgreementDialog open={agreementsDialogOpen} text={dialogAgreementTxt} handleClose={handleAgreementsDialogClickClose} />
		</Grid>
	);
};

export default Step3;
