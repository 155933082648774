import { Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormHelperText, Grid, TextField, Typography } from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import { AgreementStep1, RegisterData, Step1Errors } from '../../../../store/Register/type';
import { FormError } from '../../../../store/types';
import { validateEmail } from '../../../../helpers/utils';
import ValidationErrorIcon from './../../../../assets/icons/validation-error.svg';
import ValidationSuccessIcon from './../../../../assets/icons/validation-success.svg';
import AgreementsService from './../../../../services/agreements.service';
import { Agreement } from '../../../../store/Agreements/type';
import { errorHandler } from '../../../../helpers/errorHandler';
import React from 'react';
import AgreementDialog from '../../../shared/AgreementDialog/AgreementDialog';
import { Parameters } from '../../../../store/Parameters/type';

type Step1Props = {
	parameters: Parameters;
	setStep1Completed: React.Dispatch<React.SetStateAction<boolean>>;
	formData: RegisterData;
	setFormData: React.Dispatch<React.SetStateAction<RegisterData>>;
	setIsStep1Active: React.Dispatch<React.SetStateAction<boolean>>;
	setIsStep2Active: React.Dispatch<React.SetStateAction<boolean>>;
};
const Step1: FunctionComponent<Step1Props> = ({ parameters, setStep1Completed, formData, setFormData, setIsStep1Active, setIsStep2Active }) => {
	const [firstname, setFirstname] = useState<string>('');
	const [firstnameSuccess, setFirstnameSuccess] = useState<boolean>(false);
	const [lastname, setLastname] = useState<string>('');
	const [lastnameSuccess, setLastnameSuccess] = useState<boolean>(false);
	const [email, setEmail] = useState<string>('');
	const [emailSuccess, setEmailSuccess] = useState<boolean>(false);
	const [agreements, setAgreements] = useState<AgreementStep1[]>([]);
	const [agreementsSuccess, setAgreementsSuccess] = useState<boolean>(false);

	const [agreementsList, setAgreementsList] = useState<Agreement[]>([]);

	const [preloader, setPreloader] = useState<boolean>(true);

	const [dialogAgreementTxt, setDialogAgreementTxt] = useState<string>('');
	const [agreementsDialogOpen, setAgreementsDialogOpen] = useState(false);

	// errors
	const defaultError: FormError = {
		error: false,
		message: '',
	};
	const defaultStep1FormErrors: Step1Errors = {
		firstname: defaultError,
		lastname: defaultError,
		email: defaultError,
		agreements: defaultError,
	};
	const [step1FormErrors, setStep1FormErrors] = useState<Step1Errors>(defaultStep1FormErrors);

	const handleAgreementsDialogClickOpen = (txt: string | null) => {
		setDialogAgreementTxt(txt ? txt : '');
		setAgreementsDialogOpen(true);
	};

	const handleAgreementsDialogClickClose = () => {
		setAgreementsDialogOpen(false);
	};

	const handleFirstnameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFirstname(event.target.value);
	};
	const handleLastnameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLastname(event.target.value);
	};
	const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value);
	};
	const handleAgreementChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
		setAgreements(agreements.map((agreement, currentIndex) => (currentIndex + 1 === index ? { ...agreement, isAccepted: event.target.checked } : agreement)));
	};

	const setInputsSuccessOnTrue = () => {
		setFirstnameSuccess(true);
		setLastnameSuccess(true);
		setEmailSuccess(true);
		setAgreementsSuccess(true);
	};
	useEffect(() => {
		const fetchAgreements = async () => {
			try {
				const agreementsListReponse = await AgreementsService.getAgreementsList('AHAT', null);

				if (agreementsListReponse.data.agreements) {
					setAgreementsList(agreementsListReponse.data.agreements);
					setPreloader(false);
				}
			} catch (error) {
				errorHandler(error);
			}
		};
		fetchAgreements();
	}, []);
	useEffect(() => {
		const agreementsValues = agreementsList?.map((agreement, index) => ({
			id: agreement.id,
			indexForAgreementsCheck: index + 1,
			description: agreement.description,
			shortDescription: agreement.shortDescription,
			linkLabel: agreement.linkLabel,
			url: agreement.url,
			isRequired: agreement.isRequired,
			isAccepted: agreement.isAccepted,
		}));
		if (formData) {
			if (formData.referenceFirstname && formData.referenceLastname && formData.referenceEmail && formData.agreements) {
				setInputsSuccessOnTrue();
			}
			setFirstname(formData.referenceFirstname || '');
			setLastname(formData.referenceLastname || '');
			setEmail(formData.referenceEmail || '');
			if (formData.agreements.length !== 0) setAgreements(formData.agreements);
			if (formData.agreements.length === 0) setAgreements(agreementsValues);
		}
	}, [formData, agreementsList]);

	const onSaveFormData = () => {
		setStep1FormErrors(defaultStep1FormErrors);
		const step1Errors = defaultStep1FormErrors;
		setInputsSuccessOnTrue();

		let errorCounter = 0;
		if (firstname.trim() === '') {
			const validatedInput = {
				firstname: {
					error: true,
					message: 'To pole jest wymagane',
				},
			};

			const error = Object.assign(step1Errors, validatedInput);
			setStep1FormErrors(error);
			setFirstnameSuccess(false);
			errorCounter++;
		}
		if (firstname.trim().length > parameters.firstnameMaxLength) {
			const validatedInput = {
				firstname: {
					error: true,
					message: `Maksymalna ilość znaków: ${parameters.firstnameMaxLength}`,
				},
			};

			const error = Object.assign(step1Errors, validatedInput);
			setStep1FormErrors(error);
			setFirstnameSuccess(false);
			errorCounter++;
		}
		if (lastname.trim() === '') {
			const validatedInput = {
				lastname: {
					error: true,
					message: 'To pole jest wymagane',
				},
			};

			const error = Object.assign(step1Errors, validatedInput);
			setStep1FormErrors(error);
			setLastnameSuccess(false);
			errorCounter++;
		}
		if (lastname.trim().length > parameters.lastnameMaxLength) {
			const validatedInput = {
				lastname: {
					error: true,
					message: `Maksymalna ilość znaków: ${parameters.lastnameMaxLength}`,
				},
			};

			const error = Object.assign(step1Errors, validatedInput);
			setStep1FormErrors(error);
			setLastnameSuccess(false);
			errorCounter++;
		}
		if (!validateEmail(email)) {
			const validatedInput = {
				email: {
					error: true,
					message: 'Nieprawidłowy format email',
				},
			};

			const error = Object.assign(step1Errors, validatedInput);
			setStep1FormErrors(error);
			setEmailSuccess(false);
			errorCounter++;
		}
		if (email.trim().length > parameters.emailMaxLength) {
			const validatedInput = {
				email: {
					error: true,
					message: `Maksymalna ilość znaków: ${parameters.emailMaxLength}`,
				},
			};

			const error = Object.assign(step1Errors, validatedInput);
			setStep1FormErrors(error);
			setEmailSuccess(false);
			errorCounter++;
		}

		const requiredAgreements = agreementsList ? agreementsList.filter((agreement) => agreement.isRequired).length : 0;
		const agreementsChecked = agreements ? agreements.filter((agreement) => agreement.isAccepted && agreement.isRequired).length : 0;

		const requiredAndCheckedAgreementsAreEqual = requiredAgreements === agreementsChecked;
		if (!requiredAndCheckedAgreementsAreEqual) {
			const validatedInput = {
				agreements: {
					error: true,
					message: 'Zaznacz wymagane zgody',
				},
			};

			const error = Object.assign(step1Errors, validatedInput);
			setStep1FormErrors(error);
			errorCounter++;
		}
		if (errorCounter === 0) {
			setStep1FormErrors(defaultStep1FormErrors);
			setFormData({
				referenceFirstname: firstname,
				referenceLastname: lastname,
				referenceEmail: email,
				candidateFirstname: formData.candidateFirstname,
				candidateLastname: formData.candidateLastname,
				candidateEmail: formData.candidateEmail,
				candidateMobile: formData.candidateMobile,
				workplaceCode: formData.workplaceCode,
				fileName: formData.fileName,
				fileCode: formData.fileCode,
				fileType: formData.fileType,
				fileDescription: formData.fileDescription,
				agreements: agreements,
			});
			setStep1Completed(true);
			setIsStep1Active(false);
			setIsStep2Active(true);
		}
	};

	return (
		<Grid container className="step step-1" paddingX={4}>
			{preloader && (
				<Grid item>
					<CircularProgress color="inherit" />
				</Grid>
			)}
			{!preloader && (
				<Grid item md={12}>
					<Typography variant="h6" mb={3} className="header header-secondary">
						Formularz rekrutacyjny
					</Typography>

					<Typography className="primary-txt-style">Uzupełnij formularz, aby polecić kandydata do pracy w MEDISEPT.</Typography>
					<Typography className="primary-txt-style">W pierwszym kroku uzupełnij swoje dane i zaakceptuj wymagane zgody.</Typography>
					<Typography className="primary-txt-style">W drugim kroku będziesz poproszony o uzupełnienie danych kandydata.</Typography>

					<Typography mb={3} mt={5} className="header header-form">
						Dane osoby zgłaszającej kandydata
					</Typography>

					<Grid container display={'flex'} flexDirection={'column'} alignItems={'center'}>
						<Grid item className="form-container">
							<FormControl className="inputs_containers" fullWidth>
								{step1FormErrors.firstname.error && <img className="validation-icon" src={ValidationErrorIcon} alt="validation-error" />}
								{firstnameSuccess && <img className="validation-icon" src={ValidationSuccessIcon} alt="validation-success" />}
								<div className="validaton-mark">*</div>
								<TextField
									variant="outlined"
									fullWidth
									placeholder="Imię"
									size="small"
									className={step1FormErrors.firstname.error ? 'form-element form-element__input' : 'form-element form-element--margin-bottom form-element__input'}
									value={firstname}
									onChange={handleFirstnameChange}
									error={step1FormErrors.firstname.error}
									helperText={step1FormErrors.firstname.error && step1FormErrors.firstname.message}
								/>
							</FormControl>
							<FormControl className="inputs_containers" fullWidth>
								{step1FormErrors.lastname.error && <img className="validation-icon" src={ValidationErrorIcon} alt="validation-error" />}
								{lastnameSuccess && <img className="validation-icon" src={ValidationSuccessIcon} alt="validation-success" />}
								<div className="validaton-mark">*</div>
								<TextField
									variant="outlined"
									fullWidth
									placeholder="Nazwisko"
									size="small"
									className={step1FormErrors.lastname.error ? 'form-element form-element__input' : 'form-element form-element--margin-bottom form-element__input'}
									value={lastname}
									onChange={handleLastnameChange}
									error={step1FormErrors.lastname.error}
									helperText={step1FormErrors.lastname.error && step1FormErrors.lastname.message}
								/>
							</FormControl>
							<FormControl className="inputs_containers" fullWidth>
								{step1FormErrors.email.error && <img className="validation-icon" src={ValidationErrorIcon} alt="validation-error" />}
								{emailSuccess && <img className="validation-icon" src={ValidationSuccessIcon} alt="validation-success" />}
								<div className="validaton-mark">*</div>
								<TextField
									variant="outlined"
									fullWidth
									placeholder="E-mail"
									size="small"
									className={step1FormErrors.email.error ? 'form-element form-element__input' : 'form-element form-element--margin-bottom form-element__input'}
									type="email"
									value={email}
									onChange={handleEmailChange}
									error={step1FormErrors.email.error}
									helperText={step1FormErrors.email.error && step1FormErrors.email.message}
								/>
							</FormControl>
							<div style={{ textAlign: 'left' }}>
								{agreements?.map((agreement) => (
									<FormControl key={agreement.id} className="inputs_containers">
										{agreementsSuccess && agreement.isRequired && !agreement.isAccepted && <img className="validation-icon" src={ValidationErrorIcon} alt="validation-error" />}
										{agreementsSuccess && agreement.isAccepted && agreement.isRequired && <img className="validation-icon" src={ValidationSuccessIcon} alt="validation-success" />}
										{agreement.isRequired && <div className="validaton-mark">*</div>}
										<FormControlLabel
											className={agreement.description ? 'custom-checkbox' : 'custom-checkbox margin--margin-bottom'}
											control={<Checkbox checked={agreement.isAccepted} onChange={(e) => handleAgreementChange(e, agreement.indexForAgreementsCheck)} />}
											label={
												<React.Fragment>
													{agreement.shortDescription}{' '}
													{agreement.url && (
														<a href={agreement.url} target="_blank" rel="noreferrer">
															{agreement.linkLabel}
														</a>
													)}
												</React.Fragment>
											}
										/>
										{agreement.description && (
											<FormHelperText className="show-more">
												<span
													onClick={() => {
														handleAgreementsDialogClickOpen(agreement.description);
													}}
												>
													pokaż więcej
												</span>
											</FormHelperText>
										)}
									</FormControl>
								))}
							</div>
							{step1FormErrors.agreements.error && <div className="error-msg">{step1FormErrors.agreements.message}</div>}

							<Grid container>
								<Grid item width={'100%'} display={'flex'} justifyContent={'flex-end'}>
									<Button className="button button--primary button--primary--normal" onClick={onSaveFormData}>
										Następny krok
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<AgreementDialog open={agreementsDialogOpen} text={dialogAgreementTxt} handleClose={handleAgreementsDialogClickClose} />
				</Grid>
			)}
		</Grid>
	);
};

export default Step1;
