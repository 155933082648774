import axios from 'axios';
import { RegisterFormParams, RegisterFormResponse } from '../store/Register/type';

const API_URL = process.env.REACT_APP_API_URL;

const registerCandidate = async (params: RegisterFormParams): Promise<RegisterFormResponse> => {
	return await axios.post(API_URL + '/api/application', {
		...params,
	});
};

const exportedObject = {
	registerCandidate,
};

export default exportedObject;
