import { Button, Grid, Typography } from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import FileIcon from './../../../../assets/icons/file.svg';
import { RegisterData, RegisterFormParams } from './../../../../store/Register/type';
import { errorHandler } from './../../../../helpers/errorHandler';
import RegisterService from './../../../../services/register.service';

type Step3Props = {
	setStep3Completed: React.Dispatch<React.SetStateAction<boolean>>;
	formData: RegisterData;
	setIsStep2Active: React.Dispatch<React.SetStateAction<boolean>>;
	setIsStep3Active: React.Dispatch<React.SetStateAction<boolean>>;
	setIsStep3SummaryActive: React.Dispatch<React.SetStateAction<boolean>>;
};

declare global {
	interface Window {
		grecaptcha: ReCaptchaInstance;
	}
}

interface ReCaptchaInstance {
	ready: (callback: () => void) => void;
	execute: (siteKey: string, options: ReCaptchaExecuteOptions) => Promise<string>;
}

interface ReCaptchaExecuteOptions {
	action: string;
}

const Step3: FunctionComponent<Step3Props> = ({ setStep3Completed, formData, setIsStep2Active, setIsStep3Active, setIsStep3SummaryActive }) => {
	const onGoToPreviousStep = () => {
		setStep3Completed(false);
		setIsStep2Active(true);
		setIsStep3Active(false);
	};

	const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);

	const [recaptchaToken, setRecaptchaToken] = useState<string | null>('');
	useEffect(() => {
		const loadReCaptcha = async () => {
			await new Promise<void>((resolve) => {
				const script = document.createElement('script');
				script.src = `https://www.google.com/recaptcha/api.js?${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
				script.async = true;
				script.defer = true;
				script.addEventListener('load', resolve as unknown as EventListener);
				document.head.appendChild(script);
			});

			window.grecaptcha.ready(() => {
				window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY ? process.env.REACT_APP_RECAPTCHA_SITE_KEY : '', { action: 'sendApplication' }).then((newToken: string) => {
					setRecaptchaToken(newToken);
				});
			});
		};

		loadReCaptcha();
	}, []);

	const onGoToSummaryStep = async () => {
		const formParams: RegisterFormParams = {
			referenceFirstname: formData.referenceFirstname,
			referenceLastname: formData.referenceLastname,
			referenceEmail: formData.referenceEmail,
			candidateFirstname: formData.candidateFirstname,
			candidateLastname: formData.candidateLastname,
			candidateEmail: formData.candidateEmail,
			candidateMobile: formData.candidateMobile,
			workplaceCode: formData.workplaceCode,
			filename: formData.fileName,
			fileContent: formData.fileCode,
			description: formData.fileDescription ? formData.fileDescription : null,
			recaptcha: recaptchaToken,
			agreements: formData.agreements.map((agreement) => ({ id: agreement.id, isAccepted: agreement.isAccepted })),
		};
		setButtonDisabled(true);
		try {
			const registerCandidateResponse = await RegisterService.registerCandidate(formParams);

			if (registerCandidateResponse.data.success) {
				setStep3Completed(true);
				setIsStep3Active(false);
				setIsStep3SummaryActive(true);
			}
		} catch (error) {
			errorHandler(error);
		} finally {
			setButtonDisabled(false);
			setRecaptchaToken(null);
		}
	};
	return (
		<Grid container className="step step-1" paddingX={4}>
			<Grid item md={12}>
				<Typography variant="h6" mb={3} className="header header-secondary">
					Podsumowanie
				</Typography>

				<Typography className="primary-txt-style">Podaj dane osoby, którą polecasz na wybrane stanowisko pracy w MEDISEPT.</Typography>

				<Typography mb={3} mt={5} className="header header-form">
					Dane osoby zgłaszającej kandydata
				</Typography>
				<Grid container display={'flex'} flexDirection={'column'} alignItems={'center'}>
					<Grid item className="preview-container">
						<Typography className="preview-element preview-element__field">{formData.referenceFirstname}</Typography>
						<Typography className="preview-element preview-element__field">{formData.referenceLastname}</Typography>
						<Typography className="preview-element preview-element__field">{formData.referenceEmail}</Typography>
					</Grid>
				</Grid>
				<Typography mb={3} mt={5} className="header header-form">
					Dane kandydata do pracy w MEDISEPT
				</Typography>

				<Grid container display={'flex'} flexDirection={'column'} alignItems={'center'}>
					<Grid item className="preview-container">
						<Typography className="preview-element preview-element__field">{formData.candidateFirstname}</Typography>
						<Typography className="preview-element preview-element__field">{formData.candidateLastname}</Typography>
						<Typography className="preview-element preview-element__field">{formData.candidateEmail}</Typography>
						<Typography className="preview-element preview-element__field">{formData.candidateMobile}</Typography>
						<Typography className="preview-element preview-element__field">{formData.workplaceCode}</Typography>

						<Grid container>
							<Grid item className="preview-element__file-name" md={12}>
								<img src={FileIcon} className="preview-element__file-name__icon" alt="file-icon" />
								{formData.fileName}
							</Grid>
						</Grid>
						{formData.fileDescription && <Typography className="preview-element preview-element__field preview-element__field__textarea">{formData.fileDescription}</Typography>}

						<Grid container mt={3}>
							<Grid item width={'100%'} display={'flex'} justifyContent={'space-between'}>
								<Button className="button button--secondary button--secondary--normal" onClick={onGoToPreviousStep}>
									Poprzedni krok
								</Button>
								<Button className="button button--primary button--primary--normal" onClick={onGoToSummaryStep} style={{ opacity: buttonDisabled ? '0.3' : '1' }} disabled={buttonDisabled}>
									Potwierdzam
								</Button>
							</Grid>
							{/* <ReCAPTCHA sitekey="6LdcVq8mAAAAAKfmp0W1i929aO09bDXdKXocaK26" onChange={handleCaptchaVerification} /> */}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Step3;
