import axios from 'axios';
import { AgreementsListResponse } from '../store/Agreements/type';

const API_URL = process.env.REACT_APP_API_URL;

const getAgreementsList = async (agreementTypeCode: string, applicationUuid: string | null): Promise<AgreementsListResponse> => {
	return axios.post(API_URL + '/api/agreement/list', {
		agreementTypeCode,
		applicationUuid,
	});
};

const exportObject = {
	getAgreementsList,
};

export default exportObject;
