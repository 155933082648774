import { Grid, Typography } from '@mui/material';
import Logo from './../../../assets/logos/logo.png';
import { NavLink } from 'react-router-dom';

const Home = () => {
	return (
		<Grid container className="home">
			<Grid container className="home-header" marginY={'20px'}>
				<img src={Logo} className="home-header__logo" alt="logo" />
			</Grid>
			<Grid item className="bordered-container" md={12}>
				<div className="header-img"></div>
				<Typography variant="h5" mb={3} className="header header-primary">
					Poleć kandydata do pracy w MEDISEPT.
				</Typography>

				<Typography mb={2} className="primary-txt-style">
					Znasz kogoś kto pasowałby do naszego zespołu?
				</Typography>

				<Typography className="primary-txt-style">Widziałeś jedną z naszych ofert i znasz osobę, która świetnie odnalazłaby się w tej roli?</Typography>
				<Typography mb={2} className="primary-txt-style">
					Chcesz polecić tą osobę i otrzymać gratyfikację?
				</Typography>

				<Typography className="primary-txt-style">Świetnie się składa, bo jesteś w dobrym miejscu!</Typography>
				<Typography mb={2} className="primary-txt-style">
					Przed Tobą formularz Systemu Poleceń MEDISEPT.
				</Typography>

				<Typography mb={2} className="primary-txt-style">
					Wskazany przez Ciebie kandydat otrzyma informację o zgłoszeniu na podany adres mailowy. Jeśli wyrazi chęć udziału w rekrutacji, w ciągu 30 dni powinien zweryfikować podane przez Ciebie dane.
				</Typography>
				<Typography mb={2} className="primary-txt-style">
					Jeśli nie potwierdzi Twojego zgłoszenia, jego dane zostaną usunięte.
				</Typography>
				<Grid container>
					<Grid item width={'100%'} display={'flex'} justifyContent={'center'}>
						<NavLink className="button button--primary button--primary--wide" to="/register">
							Wypełnij formularz
						</NavLink>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default Home;
