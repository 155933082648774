import { Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Email } from 'hidden-from-bots-react';

const StepError = () => {
	const navigate = useNavigate();
	const toHomePage = () => {
		navigate('/');
	};
	return (
		<Grid container className="step step-error" paddingX={4}>
			<Grid item md={12}>
				<Typography variant="h5" mb={3} className="header header-secondary">
					Wystąpił nieznany błąd.
				</Typography>
				<Typography className="primary-txt-style">Wystąpił nieznany błąd.</Typography>
				<Typography className="primary-txt-style" mb={3}>
					Skontaktuj się z nami wysyłając maila na adres{' '}
					<Email className="email email--recruitment" email="rekrutacja@medisept.pl">
						<span style={{ display: 'none' }}>Contact email</span>
					</Email>
				</Typography>
				<Button className="button button--primary button--primary--wide" onClick={toHomePage}>
					Przejdź do strony głównej
				</Button>
			</Grid>
		</Grid>
	);
};
export default StepError;
