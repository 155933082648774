import axios from 'axios';
import {
	ApplicationConfirmParams,
	ApplicationConfirmResponse,
	ApplicationPreviewResponse,
	ApplicationRevokeResponse,
	CheckCodeResponse,
	ConfirmParams,
	ConfirmParamsResponse,
	ConfirmationCodeResponse,
} from '../store/Confirm/type';

const API_URL = process.env.REACT_APP_API_URL;

const sendConfirmationCodeForApp = async (code: string): Promise<ConfirmationCodeResponse> => {
	return await axios.get(API_URL + `/api/application/code/${code}`);
};

const resendConfirmationCodeForApp = async (code: string): Promise<ConfirmationCodeResponse> => {
	return await axios.get(API_URL + `/api/application/code/resend/${code}`);
};

const confirmCodeForApp = async (params: ConfirmParams): Promise<ConfirmParamsResponse> => {
	return await axios.post(API_URL + `/api/application/code/confirm`, {
		...params,
	});
};

const getApplicationPreview = async (code: string): Promise<ApplicationPreviewResponse> => {
	return await axios.get(API_URL + `/api/application/preview/${code}`);
};

const applicationConfirm = async (params: ApplicationConfirmParams): Promise<ApplicationConfirmResponse> => {
	return await axios.post(API_URL + `/api/application/manage`, {
		...params,
	});
};

const applicationRevoke = async (applicationUuid: string): Promise<ApplicationRevokeResponse> => {
	return await axios.post(API_URL + `/api/application/revoke`, {
		applicationUuid,
	});
};

const checkCode = async (code: string): Promise<CheckCodeResponse> => {
	return await axios.get(API_URL + `/api/application/check/${code}`);
};
const getApplicationFile = async (code: string): Promise<any> => {
	return await axios.get(
		API_URL + `/api/application/attachment/download/${code}`,

		{
			responseType: 'arraybuffer', // important
		}
	);
};
const exportedObject = {
	sendConfirmationCodeForApp,
	resendConfirmationCodeForApp,
	confirmCodeForApp,
	getApplicationPreview,
	applicationConfirm,
	applicationRevoke,
	checkCode,
	getApplicationFile,
};

export default exportedObject;
